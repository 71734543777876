
import Layout from '@/admin/layouts/Default.vue';
import { Component, Vue } from 'vue-property-decorator';
import ResponseHandlerModule from '@/admin/store/modules/responseHandler';

import SiteModule from '@/admin/store/site';
import InputEntity from '@/lib/formFactory/inputEntity';
import { Input } from '@/lib/formFactory/input.interface';

import Loading from '@/components/Loading.vue';
import Editor from '@/components/modules/Editor.vue';
import BtnDefault from '@/components/BtnDefault.vue';
import BtnSubmit from '@/components/buttons/BtnSubmit/BtnSubmit.vue';

import { getApiErrorMessage } from '@/lib/utils/Utils';
import { editPublicOffer, getPublicOffer } from '@/admin/api/publicOffer';

@Component({
    components: {
        Layout,
        Loading,
        Editor,
        BtnDefault,
        BtnSubmit,
    },
})
export default class OfferComponent extends Vue {
    editorCounter = 0;
    isLoading = true;
    publicOffer: Input | {} = {};
    messages = {
        errorGet: 'Не удалось загрузить Публичную оферту',
        errorEdit: 'Не удалось обновить Публичную оферту',
        successSave: 'Изменения сохранены',
    };

    async save(): Promise<void> {
        SiteModule.SET_IS_BLOCK(true);
        try {
            const result = await editPublicOffer({ mainText: (this.publicOffer as Input).value });
            (this.publicOffer as Input).value = result?.mainText || (this.publicOffer as Input).value;

            ResponseHandlerModule.showNotify({ message: this.messages.successSave, type: 'ok' });
        } catch (e) {
            getApiErrorMessage(e);
            ResponseHandlerModule.showNotify({ message: this.messages.errorGet, type: 'fail' });
        }
        SiteModule.SET_IS_BLOCK(false);
    }

    reset(): void {
        this.get();
    }

    async get(): Promise<void> {
        this.isLoading = true;

        try {
            const publicOffer = await getPublicOffer();
            (this.publicOffer as Input).value = publicOffer ? publicOffer.mainText : '';
            this.editorCounter++;
        } catch (e) {
            ResponseHandlerModule.showNotify({ message: this.messages.errorGet, type: 'fail' });
        }

        this.isLoading = false;
    }

    updateOfferValue(value: string): void {
        (this.publicOffer as Input).value = value;
    }

    async created(): Promise<void> {
        this.publicOffer = new InputEntity('termsOfUse').defaultValue;

        this.get();
    }

    beforeDestroy(): void {
        this.isLoading = true;
        this.publicOffer = {};
    }
}
