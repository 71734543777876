import { makeFormRequest, makeGetRequest } from '@/api/helpers';
import API from './../config/api-routers';

export const getPublicOffer = async (): Promise<{ mainText: string }> => {
    const result = await makeGetRequest(API.publicOffer.get);

    return result.data.result.data.items;
};

export const editPublicOffer = async (data: any): Promise<{ mainText: string }> => {
    const result = await makeFormRequest(API.publicOffer.edit, data);

    return result.data.result.item.items;
};
